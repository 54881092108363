<template>
  <b-card title="Company Tree Manager">
    <b-row>
      <b-col cols="6" md="6">
        <b-button
          class="ml-2 mb-1"
          style="width: 100%"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          @click="changeTree(1)"
        >
          Collapse
        </b-button>
      </b-col>
      <b-col cols="6" md="6">
        <b-button
          style="width: 100%"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-danger"
          @click="changeTree(0)"
        >
          Expand
        </b-button>
      </b-col>
      <!-- <b-col cols="12" md="12">
        <b-button
          class="ml-1 mb-1"
          style="width: 100%"
          v-if="$can('company_tree_create')"
          variant="outline-info"
          v-b-modal.modal-import-record
        >
          <feather-icon icon="UploadCloudIcon" class="mr-50" />
          <span class="align-middle">Import</span>
        </b-button> -->
      </b-col>
    </b-row>
    <b-col md="12" v-if="treeData.length">
      <v-jstree
        ref="tree"
        :data="treeData"
        allow-batch
        whole-row
        @item-click="itemClick"
      ></v-jstree>
      <!-- <tree
        :data="treeData"
        :options="options"
        ref="tree"
      /> -->
    </b-col>
    <!-- Modal: import Record -->

    <b-modal
      id="modal-import-record"
      title="Import"
      centered
      :hide-footer="true"
    >
      <div
        v-if="$can('company_tree_create')"
        class="d-flex ml-2 align-items-center"
      >
        <b-form autocomplete="off" id="bulk-import" name="bulk-import" style="width: 100%">
          <file-pond
            ref="bulkdata"
            name="bulkdata"
            max-file-size="10MB"
            max-files="1"
            label-idle="Company Tree Import"
            allow-multiple="false"
            accepted-file-types="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, text/csv, application/csv"
            file-validate-type-label-expected-types="Expects a Excel or CSV file"
            @addfile="fileSelected"
            @removefile="fileRemoved"
          />
          <small class="text-warning flex items-center">
            <feather-icon icon="AlertCircleIcon" class="mr-25" />
            Allowed formats are
            <b>.xlsx, .csv</b>
          </small>
          <br />
          <div class="text-center w-100 mt-1">
            <button
              type="button"
              class="btn btn-success"
              @click="importBulkData"
              v-if="isFileSelected"
            >
              Import
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import VJstree from "vue-jstree";
import LiquorTree from "liquor-tree";

import { BRow, BCol } from "bootstrap-vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import Ripple from "vue-ripple-directive";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

Vue.use(VueCookies);

export default {
  components: {
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VJstree,
    tree: LiquorTree,
    FilePond,
  },
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
    dataLocal: {
      type: Object,
      default: () => {},
    },
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-company-trees";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, companyTreeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  data() {
    return {
      isFileSelected: false,
      treeData: [],
    };
  },
  methods: {
    itemClick(node) {
      this.$emit('update:selected', node.model);
      this.$emit('update:data_local', node.model);
    },
    changeTree(mode) {
      if (mode == 1) {
        this.treeData.forEach((node, index) => {
          node.closeChildren();
        });
      } else {
        this.treeData.forEach((node, index) => {
          node.openChildren();
        });
      }
    },
    fileSelected(e) {
      this.isFileSelected = true;
    },
    fileRemoved(e) {
      this.isFileSelected = false;
    },
    importBulkData() {
      const self = this;
      const formData = new FormData(document.getElementById("bulk-import"));
      formData.delete("bulkdata");

      var bulkdata = [];
      if (self.$refs.bulkdata.getFiles().length) {
        if (
          self.$refs.bulkdata.getFiles()[0].fileType !=
            "application/vnd.ms-excel" &&
          self.$refs.bulkdata.getFiles()[0].fileType !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          self.$refs.bulkdata.getFiles()[0].fileType != ".xlsx" &&
          self.$refs.bulkdata.getFiles()[0].fileType != "text/csv" &&
          self.$refs.bulkdata.getFiles()[0].fileType != "application/csv"
        ) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Only Excel and CSV file are allowed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Only Excel and CSV file are allowed`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
          self.is_loading = false;
          return;
        }
      }

      if (this.$refs.bulkdata) {
        bulkdata = this.$refs.bulkdata.getFiles();
        if (bulkdata[0]) {
          formData.append("bulkdata", bulkdata[0].file);
        }
        this.$store
          .dispatch("app-company-trees/import", {
            data: formData,
            url: "company-tree/process-import",
          })
          .then((res) => {
            this.$bvModal.hide("modal-import-record");
            this.isFileSelected = false;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Record imported with success.",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Record imported with success.',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            
          })
          .catch((err) => {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Record imported with error.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: err.toString(),
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Record imported with error.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });
      }
    },
    fetchCompanyTreeList() {
      this.$store
        .dispatch("app/fetchCompanyTreeList")
        .then((res) => {
          this.treeData = res.data.data;
          this.$refs.tree = this.treeData;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetching Company Tree List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetching Company Tree List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
  },
  created() {
    this.fetchCompanyTreeList();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
