<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox class="mr-2" v-model="isDeleteMultipleChecked">
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div
              v-if="selectedEmployees.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Search Name/Email"
                />
              </b-input-group>
            </div>
            <div v-else class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                pill
                class=""
                @click="BulkAddUnderManager()"
              >
                Add Under Manager
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedEmployees"
        name="selectedEmployees"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item"> </b-form-checkbox>
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                />
                <!-- :to="{
                    name: 'view-employee',
                    params: { id: data.item.hashid },
                  }" -->
              </template>
              <b-link
                v-if="$can('employee_show')"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.name"
              >
                {{ data.item.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                class="d-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.name"
              >
                {{ data.item.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!-- Column: User -->
          <template #cell(reporting_manager_name)="data">
            <b-link
              v-if="$can('employee_show')"
              :to="{
                name: 'view-employee',
                params: { id: data.item.reporting_manager },
              }"
              class="font-weight-bold d-inline-block text-nowrap"
              v-b-tooltip.hover.v-default
              :title="data.item.reporting_manager_name"
            >
              {{ data.item.reporting_manager_name | str_limit(15) }}
            </b-link>
            <span
              v-else
              class="d-block text-nowrap"
              v-b-tooltip.hover.v-default
              :title="data.item.reporting_manager_name"
            >
              {{ data.item.reporting_manager_name | str_limit(15) }}
            </span>
          </template>

          <!-- Column: User -->
          <template #cell(email)="data">
            <span v-b-tooltip.hover.v-default :title="data.item.email">
              {{ data.item.email | str_limit(15) }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="
                  AddUnderManager(data.item.hashid, data.item.reporting_manager)
                "
              >
                <!-- <feather-icon icon="PlusIcon" size="13" class="mr-50" /> -->
                <span>Add Under Manager</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                v-b-modal.modal-add-under-department
                @click="formData.person_id = data.item.hashid"
              >
                <!-- <feather-icon icon="PlusIcon" size="13" class="mr-50" /> -->
                <span>Add Under Department</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedEmployees.length == 0"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-add-under-department"
      ref="modal"
      title="Add Under Department"
      @ok="AddUnderDepartment"
      :no-close-on-backdrop="true"
    >
      <div>
        <b-form-group label-for="department" label="Departments">
          <v-select
            v-model="formData.parent_id"
            id="department"
            :options="departments"
            placeholder="Select Department"
            name="department"
            :reduce="(selected) => selected.code"
            label="label"
            :clearable="false"
          >
          </v-select>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BButton,
  BBadge,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup,
  BPagination,
  BButtonToolbar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import employeeStoreModule from "../companyTreeStoreModule";
import useEmployeesList from "./useCompanyTreeEmployeesList";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BBadge,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    FilePond,
    /* eslint-enable */
    BButtonToolbar,
    BDropdown,
    BDropdownItem,
    BCardActions,
    BCardActionsContainer,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-employees";
    const isDeleteMultipleChecked = ref(false);
    const selectedEmployees = ref([]);
    const employeeDeleteCount = ref(0);
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "All", value: null },
      { label: "Active", value: "1" },
      { label: "In Active", value: "0" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      usersMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
      filterByDesignationId,
    } = useEmployeesList();

    watch(isDeleteMultipleChecked, (val) => {
      selectedEmployees.value = [];
      if (val) {
        usersMetaData.value.forEach((employee) => {
          selectedEmployees.value.push(employee.hashid);
        });
      }
    });

    watch(selectedEmployees, (val) => {
      employeeDeleteCount.value = val.length;
    });

    return {
      fetchUsers,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      filterByDesignationId,
      isDeleteMultipleChecked,
      employeeDeleteCount,
      selectedEmployees,
      tableColumns,
    };
  },
  data() {
    return {
      isFileSelected: false,
      list_roles: [],
      filter: {
        roleFilter: null,
      },
      designationOptions: [],
      departments: [],
      customFields: [],
      formData: {
        url: "company_tree/create_node",
      },
      show: false,
    };
  },
  created() {
    this.getCustomFields();
  },
  methods: {
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-company-trees/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.employeeOptions = res.data.employee_options;
          self.departments = res.data.departments;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    AddUnderManager(user_hashid, parent_id) {
      const self = this;

      self.formData.url = "company_tree/add_under_manager";
      self.formData.params = {
        node_type: "Person",
        person_id: user_hashid,
        parent_id: parent_id,
      };

      this.$store
        .dispatch("app/store", self.formData)
        .then((res) => {
          self.getCustomFields();
          this.refetchData();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Company Tree Updated",
          //     icon: "CheckIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Company Tree Updated',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          self.getCustomFields();
          this.refetchData();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Company Tree Updation Failed",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Company Tree Updation Failed',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    AddUnderDepartment() {
      const self = this;

      self.formData.url = "company_tree/add_under_department";
      self.formData.params = {
        node_type: "Person",
        person_id: self.formData.person_id,
        parent_id: self.formData.parent_id,
      };

      this.$store
        .dispatch("app/store", self.formData)
        .then((res) => {
          self.getCustomFields();
          this.refetchData();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Company Tree Updated",
          //     icon: "CheckIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Company Tree Updated',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          self.getCustomFields();
          this.refetchData();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Company Tree Updation Failed",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Company Tree Updation Failed',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    BulkAddUnderManager() {
      var result = this.selectedEmployees.map((person) => ({
        person_id: person.hashid,
        parent_id: person.reporting_manager,
        node_type: "Person",
      }));

      const self = this;

      self.formData.url = "company-tree/mass_add_under_manager";
      self.formData.params = {
        nodes: result,
      };

      this.$store
        .dispatch("app/storeNoHeader", self.formData)
        .then((res) => {
          self.getCustomFields();
          this.refetchData();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Company Tree Updated",
          //     icon: "CheckIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Company Tree Updated',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          self.getCustomFields();
          this.refetchData();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Company Tree Updation Failed",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Company Tree Updation Failed',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style>
.filepond--panel-center .filepond--panel-root {
  height: 40px !important;
}
</style>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.filepond--credits {
  display: none;
}
</style>
