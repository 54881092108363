<template>
  <b-row>
    <b-col cols="12">
      <template>
        <!-- First Row -->
        <b-row>
          <b-col cols="4" xl="4" lg="4" md="4">
            <company-tree
              :selected.sync="selected"
              :data_local.sync="data_local"
            />
          </b-col>
          <b-col cols="8">
            <div v-if="isCreating && !selected">
              <form-wizard
                color="#38B22D"
                :title="null"
                :subtitle="null"
                shape="square"
                finish-button-text="Submit"
                back-button-text="Previous"
                class="mb-3"
                @on-complete="createFormSubmitted()"
              >
                <!-- Node Create tab -->
                <tab-content
                  title="Create Tree Node"
                  icon="feather icon-file-text"
                  :before-change="validationForm"
                >
                  <validation-observer ref="accountRules" tag="form">
                    <b-form autocomplete="off" id="node-create-form" class="p-2">
                      <b-row>
                        <!-- <b-col md="6">
                        <div class="demo-inline-spacing">
                          <b-form-checkbox v-model="data_local.status" switch inline>
                            Status
                          </b-form-checkbox>
                        </div>
                      </b-col> -->
                        <b-col md="6">
                          <b-form-group
                            label="Type select"
                            label-for="type-select"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Node Type"
                              rules="required"
                            >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.node_type"
                                :options="typeList"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                placeholder="Select Node Type"
                                @input="onChange($event)"
                                input-id="type"
                                :clearable="false"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="teamSelected">
                          <b-form-group
                            label="Select Team"
                            label-for="select-team"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Select Team"
                              rules="required"
                            >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.team_id"
                                :options="teamList"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                placeholder="Select Team"
                                input-id="select-team"
                                :clearable="false"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="personSelected">
                          <b-form-group
                            label="Select Person"
                            label-for="select-person"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Select Person"
                              rules="required"
                            >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.person_id"
                                :options="employeeListOptions"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                placeholder="Select Person"
                                input-id="select-person"
                                :clearable="false"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="!personSelected && !teamSelected">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="name"
                              rules="required"
                            >
                              <label class="required-l" for="name"
                                >Department Name</label
                              >
                              <b-form-input
                                id="name"
                                v-model="data_local.name"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Department Name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="parentsAvailable">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="parent-node"
                              rules="required"
                            >
                              <label class="required-l" for="parent-node"
                                >Parent Node</label
                              >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.parent_id"
                                :options="companyTreeNodesList"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                placeholder="Select Parent Node"
                                input-id="parent-node"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- <b-col md="6" >
                        <b-form-group label="Node Color" label-for="node-color">
                          <validation-provider
                            #default="{ errors }"
                            name="node-color"
                          >
                            <colour-picker
                              v-model="data_local.node_color"
                              label="Pick Colour"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                        <b-col md="6">
                          <b-form-group
                            label="Node Image"
                            label-for="node_image"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="node_image"
                            >
                              <file-pond
                                ref="pond"
                                name="node_image"
                                max-file-size="2MB"
                                max-files="1"
                                allow-multiple="false"
                                accepted-file-types="image/*"
                                file-validate-type-label-expected-types="Expects a image file"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </tab-content>
              </form-wizard>
            </div>
            <div v-if="selected">
              <form-wizard
                color="#38B22D"
                :title="null"
                :subtitle="null"
                shape="square"
                finish-button-text="Submit"
                back-button-text="Previous"
                class="mb-3"
                @on-complete="formSubmitted()"
              >
                <!-- Node Update tab -->
                <tab-content
                  title="Update Tree Node"
                  icon="feather icon-file-text"
                  :before-change="validationForm"
                >
                  <validation-observer ref="accountRules" tag="form">
                    <b-form autocomplete="off" id="node-update-form" class="p-2">
                      <b-row>
                        <!-- <b-col md="6">
                        <div class="demo-inline-spacing">
                          <b-form-checkbox v-model="data_local.status" switch inline>
                            Status
                          </b-form-checkbox>
                        </div>
                      </b-col> -->
                        <b-col md="6">
                          <b-form-group
                            label="Type select"
                            label-for="type-select"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Node Type"
                              rules="required"
                            >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.node_type"
                                :options="typeList"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                placeholder="Select Node Type"
                                @input="onChange($event)"
                                input-id="type"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="teamSelected">
                          <b-form-group
                            label="Select Team"
                            label-for="select-team"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Select Team"
                              rules="required"
                            >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.team_id"
                                :options="teamList"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                placeholder="Select Team"
                                input-id="select-team"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="personSelected">
                          <b-form-group
                            label="Select Person"
                            label-for="select-person"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Select Person"
                              rules="required"
                            >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.person_id"
                                :options="employeeListOptions"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                item-value="code"
                                placeholder="Select Person"
                                input-id="select-person"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="!personSelected && !teamSelected">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="name"
                              rules="required"
                            >
                              <label class="required-l" for="name"
                                >Department Name</label
                              >
                              <b-form-input
                                id="name"
                                v-model="data_local.name"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Department Name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="parentsAvailable">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="parent-node"
                              rules="required"
                            >
                              <label class="required-l" for="parent-node"
                                >Parent Node</label
                              >
                              <v-select
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                v-model="data_local.parent_id"
                                :options="companyTreeNodesList"
                                class="w-100"
                                label="label"
                                :reduce="(val) => val.code"
                                placeholder="Select Parent Node"
                                input-id="parent-node"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- <b-col md="6" >
                        <b-form-group label="Node Color" label-for="node-color">
                          <validation-provider
                            #default="{ errors }"
                            name="node-color"
                          >
                            <colour-picker
                              v-model="data_local.node_color"
                              label="Pick Colour"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                        <b-col md="6">
                          <b-form-group
                            label="Node Image"
                            label-for="node_image"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="node_image"
                            >
                              <file-pond
                                ref="pond"
                                name="node_image"
                                max-file-size="2MB"
                                max-files="1"
                                allow-multiple="false"
                                accepted-file-types="image/*"
                                file-validate-type-label-expected-types="Expects a image file"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </tab-content>
              </form-wizard>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-col>
    <b-col cols="12">
      <CompanyTreeEmployeeList />
    </b-col>
  </b-row>
</template>

<script>
import VJstree from "vue-jstree";
import Ripple from "vue-ripple-directive";
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import LiquorTree from "liquor-tree";
Vue.use(VueCookies);
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ColourPicker from "vue-colour-picker";
import store from "@/store";
import companyTreeStoreModule from "../companyTreeStoreModule";
import CompanyTree from "./CompanyTree.vue";
import CompanyTreeEmployeeList from "./CompanyTreeEmployeeList.vue";

export default {
  components: {
    BRow,
    BCol,
    VJstree,
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    VBTooltip,
    flatPickr,
    vSelect,
    "colour-picker": ColourPicker,
    tree: LiquorTree,
    CompanyTree,
    CompanyTreeEmployeeList,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      numeric,
      isCollapsedSelected: false,
      isCreating: true,
      is_loading: {
        submit: false,
      },
      redirectRegularization: "",
      data_local: {
        title: "",
        node_type: "Department",
        team_id: null,
        parent_id: null,
        person_id: null,
        get_customfields_data: [],
      },
      customFields: [],
      teamSelected: false,
      personSelected: false,
      parentsAvailable: false,
      userData: this.$cookies.get("userData"),
      teamList: [],
      employeeListOptions: [],
      companyTreeNodesList: [],
      typeList: [
        {
          label: "Department",
          code: "Department",
        },
        {
          label: "Team",
          code: "Team",
        },
        {
          label: "Person",
          code: "Person",
        },
      ],
      treeData: [],
      options: {
        checkbox: false,
      },
      selected: null,
    };
  },
  watch: {
    selected: function (val) {
      console.log(val.team_id);
      console.log(val.person_id);
      console.log(val.parent_id);
      this.data_local.name = val.name;
      this.data_local.node_type = val.node_type;
      if (val.team_id) {
        this.teamSelected = true;
        this.data_local.team_id = val.team_id;
      } else {
        this.teamSelected = false;
      }
      if (val.person_id) {
        this.personSelected = true;
        this.data_local.person_id = val.person_id;
      } else {
        this.personSelected = false;
      }
      if (val.parent_id) {
        this.parentsAvailable = true;
        this.data_local.parent_id = val.parent_id;
      } else {
        this.parentsAvailable = false;
      }
      this.data_local.node_color = val.node_color;
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-company-trees";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, companyTreeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    itemClick(node) {
      console.log(node.model.node_id + " clicked !");
    },
    changeTree(mode) {
      if (mode == 1) {
        this.isCollapsedSelected = true;
      } else {
        this.treeData.forEach((node, index) => {
          this.treeData[index].opened = true;
          console.log(this.treeData[index]);
        });
      }
    },
    createFormSubmitted() {
      const self = this;
      const formData = new FormData(
        document.getElementById("node-create-form")
      );
      formData.append("node_type", self.data_local.node_type);
      formData.append("team_id", self.data_local.team_id);
      formData.append("person_id", self.data_local.person_id);
      formData.append("parent_id", self.data_local.parent_id);
      formData.append("name", self.data_local.name);
      //formData.append("node_color", self.data_local.node_color);
      formData.delete("node_image"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());
      if (files.length) {
        formData.append(`node_image`, files[0].file);
      }
      this.is_loading.submit = true;
      this.$store
        .dispatch("app-company-trees/addCompanyTree", formData)
        .then((res) => {
          this.is_loading.submit = false;
          document.getElementById("node-create-form").reset();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Company Tree Node Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Company Tree Node Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.$router.go();
        })
        .catch((error) => {
          this.is_loading.submit = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Company Tree Node Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Company Tree Node Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-regularizations/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.regularizationUrgencyStatusOption = res.data.meta.urgency_status;
          self.regularizationStatusOption = res.data.meta.status;
          self.regularizationTypeOption = res.data.meta.type;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Oops! Custom Fields Getting Failed`,
                      icon:'BellIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                        },
                        {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                        })
        });
    },
    onChange(e) {
      if (e == "Team") {
        this.teamSelected = true;
      } else {
        this.teamSelected = false;
      }
      if (e == "Person") {
        this.personSelected = true;
      } else {
        this.personSelected = false;
      }
    },
    fetchAllTeamsList() {
      this.$store
        .dispatch("app/fetchAllTeamsList")
        .then((res) => {
          this.teamList = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Team List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Team List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Team List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchCompanyTreeList() {
      this.$store
        .dispatch("app/fetchCompanyTreeList")
        .then((res) => {
          this.treeData = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetching Company Tree List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetching Company Tree List failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchCompanyTreeNodesList() {
      this.$store
        .dispatch("app/fetchCompanyTreeNodesList")
        .then((res) => {
          this.companyTreeNodesList = res.data.data;
          if (this.companyTreeNodesList.length) {
            this.parentsAvailable = true;
          }
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(
        document.getElementById("node-update-form")
      );
      formData.append("node_type", self.data_local.node_type);
      formData.append("team_id", self.data_local.team_id);
      formData.append("person_id", self.data_local.person_id);
      formData.append("parent_id", self.data_local.parent_id);
      formData.append("name", self.data_local.name);
      //formData.append("node_color", self.data_local.node_color);
      formData.delete("node_image"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());
      if (files.length) {
        formData.append(`node_image`, files[0].file);
      }

      this.is_loading.submit = true;

      this.$store
        .dispatch("app-company-trees/updateCompanyTree", {
          id: self.data_local.node_id,
          data: formData,
        })
        .then((res) => {
          this.is_loading.submit = false;
          document.getElementById("node-update-form").reset();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Company Tree Updated",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Company Tree Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.$router.go();
        })
        .catch((error) => {
          this.is_loading.submit = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Holiday updating Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Holiday updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
  },
  computed: {
    isCollapsed() {
      alert(this.isCollapsedSelected);
      if (this.isCollapsedSelected == true) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.fetchCompanyTreeList();
    this.fetchAllTeamsList();
    this.fetchEmployeeList();
    this.fetchCompanyTreeNodesList();
  },
  //   beforeMount(){
  //     this.fetchCompanyTreeList();
  //  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
